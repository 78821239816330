<template>
    <div>
        <b-tabs vertical pills lasy>
            <b-tab v-for="category in categories" :key="category.code" lazy :title="category.name">
                <template #title>
                    <icon-category :variant="category.variantTheme" v-model="category.code"/>
                </template>
                <event-category-compulsories 
                    :competition_id="d_competition_id"
                    :level="d_level" :event="d_event" 
                    :round_number="d_round_number" 
                    :category="category.code"
                    :year="d_year"
                    :maxAwaitedCompulsories="d_awaitedCompulsories"
                    @change="compulsories_change"
                    >
                    <template #prepend><h3>Figures des {{ category.name }}</h3></template>
                </event-category-compulsories>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { RST_COMPETITION, SEASON_COMPETITION_TYPE_SCOPE_LEVEL, RST_REF_CATEGORY, RST_EVENT_COMPULSORY } from "@/LocalDB";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import EventCategoryCompulsories from './EventCategoryCompulsories.vue';
import IconCategory from '@/components/IconCategory.vue';

export default {
    components: { EventCategoryCompulsories, IconCategory },
    props:{
        competition_id:{type:String, required:true},
        level:{type:String, required:true},
        event: {type:String, required:true},
        round_number:{type:Number, required:true},
    },
    data(){
        return {
            d_competition_id: this.competition_id,
            d_level: this.level,
            d_event: this.event,
            d_round_number: this.round_number,
            d_year: null,

            data_synchronized: false,
            categories: [],

            d_awaitedCompulsories: 0,
        }
    },
    methods:{
        async refresh(){
            await this.showRefresher();
            this.data_synchronized = await CompetitionsConfigurationHelper.refreshLevelCategories(this.competition_id, this.level);
            var COMPETITION = RST_COMPETITION.query().where("competition_id", this.d_competition_id).first();
            this.d_year = COMPETITION.year;
            this.d_awaitedCompulsories = SEASON_COMPETITION_TYPE_SCOPE_LEVEL.query()
                .where("year", this.d_year)
                .where("competition_type", COMPETITION.competition_type)
                .where("scope", COMPETITION.scope)
                .where("level", this.d_level)
                .first().precision_events_compulsories;
            this.categories = RST_REF_CATEGORY.query().where("competition_id",this.d_competition_id).where("level", this.d_level).where(cat => cat.effective_competitors_count > 0).get()
                .map(cat => { 
                    var nbCompulsories = RST_EVENT_COMPULSORY.query().where("competition_id", this.d_competition_id).where("level", this.d_level).where("category", cat.CATEGORY.code).where("event", this.d_event).where("round_number", this.d_round_number).count();
                    var theme = this.getCategoryTheme(nbCompulsories);
                    return { ...cat, ...cat.CATEGORY, variantTheme: theme  };
                });
            this.categories.sort((a,b) => a.displayOrder - b.displayOrder);
            this.hideRefresher();
        },
        getCategoryTheme(nbCompulsories){
            var theme = (nbCompulsories == 0)
                                ? "danger"
                                : ((nbCompulsories == this.d_awaitedCompulsories)
                                    ? "success"
                                    : "warning"
                                  );
            //console.log("getCategoryTheme(" + nbCompulsories+") -> ", theme);
            return theme;
        },
        compulsories_change(newSelection)
        {
            //console.log('compulsories_change', newSelection);
            if(newSelection == null) newSelection = [];
            var index = this.categories.findIndex(cat => cat.code == newSelection.category);
            this.categories[index].variantTheme = this.getCategoryTheme(newSelection.compulsories.length);
        }
    },
    async mounted(){
        await this.refresh();
    }
}
</script>
