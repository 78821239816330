<template>
    <div>
        <slot name="prepend"></slot>

                <div v-for="compulsory in compulsories" :key="compulsory.id" >
                    <div class="compulsory">
                    <view-icon @click="viewedCompulsory=compulsory" />&nbsp;
                    <b-checkbox inline
                        size="xl"
                        :value="compulsory.compulsory_id"
                        v-model="selected"
                        @change ="selectCompulsories"
                        >
                        <b-badge pill variant="info">{{ compulsorySelectionOrder(compulsory) }}</b-badge>
                        {{ compulsory.code }} - {{ compulsory.name }}
                    </b-checkbox>
                    <view-icon @click="showCompulsory(compulsory.code)" v-if="false" />
                    </div>
                </div>
                <b-container fluid v-if="viewedCompulsory!=null" style="border:solid 1px var(--ffvlBleuLogo); border-radius: 5px; background-color:white;">
                    <div style="margin-top:3px; margin-bottom:2px;display:flex;justify-content:space-around;">
                        <b-checkbox inline v-model="viewedCompulsoryIncludeText">Inclure le texte</b-checkbox>
                        <b-form-radio-group inline v-show="viewedCompulsoryTeamOptionVisible" v-model="viewedCompulsoryTeamOption" :options="[{text:'3', value:'3'}, {text:'4', value:'4'}, {text:'5', value:'5'}]" >
                        </b-form-radio-group>
                    </div>
                    <compulsory-image v-model="viewedCompulsory.code" :includeText="viewedCompulsoryIncludeText" :teamOption="viewedCompulsoryTeamOption" />
                </b-container>
    </div>
</template>

<script>
import { RST_COMPETITION, SEASON_COMPULSORY, RST_EVENT_COMPULSORY, SEASON_COMPETITION_TYPE_SCOPE_LEVEL } from "@/LocalDB";
import EventsConfigurationHelper from '@/services/helpers/eventsConfigurationHelper';
import ViewIcon from "@/components/Icons/ViewIcon.vue";
import CompulsoryImage from "../../../../components/CompulsoryImage.vue";

export default {
    components: { ViewIcon, CompulsoryImage },
    props:{
        competition_id:{type:String, required:true},
        level:{type:String, required:true},
        event: {type:String, required:true},
        round_number:{type:Number, required:true},
        category: {type:String, required:true},
        year: { type:Number, required:true},
        maxAwaitedCompulsories: { type:Number, default:null }
    },
    data(){
        return {
            d_competition_id: this.competition_id,
            d_level: this.level,
            d_event: this.event,
            d_round_number: this.round_number,
            d_category: this.category,
            d_year: this.year,
            d_maxCompulsories: this.maxAwaitedCompulsories,

            compulsories: [],
            selected: [],
            viewedCompulsory: null,
            viewedCompulsoryIncludeText: false,
            viewedCompulsoryTeamOptionSelected:3,
            selectedCompulsoryCode: '',
        }
    },
    computed:{
        selectedCompulsoryJpg(){
            var images = require.context('@/assets/', false, /\.JPG$/);
            return images('./assets/Compulsories/' + this.selectedCompulsoryCode + '.JPG');
        },
        viewedCompulsoryTeamOptionVisible(){
            console.log(this.viewedCompulsory);
            return this.viewedCompulsory != null && this.viewedCompulsory.category == 'DT';
        },
        viewedCompulsoryTeamOption:{
            get(){ return this.viewedCompulsoryTeamOptionVisible ? this.viewedCompulsoryTeamOptionSelected : ''},
            set(newVal) { if(this.viewedCompulsoryTeamOptionVisible) this.viewedCompulsoryTeamOptionSelected = newVal;}
        },
    },
    methods:{
        async refresh(){
            await this.showRefresher();
            this.dataSynchronized = await EventsConfigurationHelper.refreshEventCompulsories(this.d_competition_id, this.d_level, this.d_category, this.d_event, this.d_round_number)
            this.compulsories = SEASON_COMPULSORY.query()
                .where("year", this.d_year)
                .where(item => item.COMPULSORY.level == this.d_level && item.COMPULSORY.category == this.d_category)
                .orderBy(item => item.COMPULSORY.code)
                .get()
                .map(item => { return { ...item, ...item.COMPULSORY }});
            if(this.maxCompulsories == null){
                var COMPETITION = RST_COMPETITION.query().where("competition_id", this.d_competition_id).first();
                this.maxCompulsories = SEASON_COMPETITION_TYPE_SCOPE_LEVEL.query()
                    .where("year", this.d_year)
                    .where("competition_type", COMPETITION.competition_type)
                    .where("scope", COMPETITION.scope)
                    .where("level", this.d_level)
                    .first().precision_events_compulsories;
            }
            this.selected = RST_EVENT_COMPULSORY.query()
                  .where("competition_id", this.d_competition_id)
                  .where("round_number", this.d_round_number)
                  .where("level", this.d_level)
                  .where("category", this.d_category)
                  .where("event", this.d_event)
                  .orderBy("order")
                  .get()
                  .map(ec => ec.compulsory_id);
            this.hideRefresher();
        },
        async selectCompulsories(newCompulsories){
            this.selectedCompulsoryCode = '';
            try{
                await this.showSaver();
                //console.log('selectCompulsories => ', newCompulsories, this.maxCompulsories);
                if(newCompulsories.length > this.maxCompulsories){
                    newCompulsories = newCompulsories.slice(1);
                }
                var result = await EventsConfigurationHelper.selectCompulsories(this.d_competition_id, this.d_level, this.d_category, this.d_event, this.d_round_number, newCompulsories)
                this.selected = newCompulsories;
                this.toastAsyncSaved(result, "Figures de précision choisies mises à jour");
                this.$emit('change', { category: this.d_category, compulsories: newCompulsories} );
            }catch(error){
                this.toastSaveFailure(error);
            }
            this.hideSaver();
        },
        compulsorySelectionOrder(compulsory){
            var foundIndex = this.selected.indexOf(compulsory.compulsory_id);
            if(foundIndex >= 0)
                return foundIndex+1;
            return '';
        },
        showCompulsory(code){
            this.selectedCompulsoryCode = code;
        }
    },
    async mounted(){
        await this.refresh();
    }
}   
</script>
